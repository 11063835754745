import { WritableSignal } from '@angular/core'
import { IConfig } from '../services/config.service'
import { IConversionProcessor } from './processors'

export const processV2toV3: IConversionProcessor = {
  endVersion: '3',
  process(config: WritableSignal<IConfig>): void {
    updateSeoFaviconKey(config)
    updateSeoSocialImgKey(config)
    updateGeneralDefaultImgKey(config)
    updateHeaderLogoSrcKey(config)
    updateHeroBannerImageSrcKey(config)
  }
}

const convertToMediaData = (src: string = '') => {
  const fileName = src.split('.').slice(0, -1).join('.')
  const fileExt = src.split('.').pop()

  const makeUrls = (name: string, ext: string = '') => {
    if (name.includes('/_default/') || name.includes('/source_images/')) {
      return {
        '32x32xc': `${name}.${ext}`,
        '250x0xc': `${name}.${ext}`,
        '648x486xc': `${name}.${ext}`,
        '1200x630xc': `${name}.${ext}`,
        '2000x0xc': `${name}.${ext}`,
        '2000x800xc': `${name}.${ext}`,
        '2000x1500xc': `${name}.${ext}`,
        '2000x1800xc': `${name}.${ext}`,
      }
    } else {
      return {
        '32x32xc': `${name}.32x32xc.${ext}`,
        '250x0xc': `${name}.250x0xc.${ext}`,
        '648x486xc': `${name}.648x486xc.${ext}`,
        '1200x630xc': `${name}.1200x630xc.${ext}`,
        '2000x0xc': `${name}.2000x0xc.${ext}`,
        '2000x800xc': `${name}.2000x800xc.${ext}`,
        '2000x1500xc': `${name}.2000x1500xc.${ext}`,
        '2000x1800xc': `${name}.2000x1800xc.${ext}`,
      }
    }
  }

  return {
    kb: 0,
    name: src.split('/').pop() || '',
    originalFileName: src.split('/').pop() || '',
    updated: new Date().toISOString(),
    updatedFormatted: new Date().toLocaleDateString('en-US'),
    width: 0,
    height: 0,
    urls: makeUrls(fileName, fileExt),
  }
}

const updateSeoFaviconKey = (config: WritableSignal<IConfig>) => {
  if (config().seo.data.favicon_img) {
    config().seo.data.favicon_img_data = convertToMediaData(config().seo.data.favicon_img)
    delete config().seo.data.favicon_img
  }
}

const updateSeoSocialImgKey = (config: WritableSignal<IConfig>) => {
  if (config().seo.data.social_img) {
    config().seo.data.social_img_data = convertToMediaData(config().seo.data.social_img)
    delete config().seo.data.social_img
  }
}

const updateGeneralDefaultImgKey = (config: WritableSignal<IConfig>) => {
  if (config().general.data.default_img) {
    config().general.data.default_img_data = convertToMediaData(config().general.data.default_img)
    delete config().general.data.default_img
  }
}

const updateHeaderLogoSrcKey = (config: WritableSignal<IConfig>) => {
  if (config().header.data.logo.src) {
    config().header.data.logo.src_data = convertToMediaData(config().header.data.logo.src)
    delete config().header.data.logo.src
  }
}

const updateHeroBannerImageSrcKey = (config: WritableSignal<IConfig>) => {
  if (config().page.home.components.heroBanner.data.imageSrc) {
    config().page.home.components.heroBanner.data.imageSrcData = convertToMediaData(config().page.home.components.heroBanner.data.imageSrc)
    delete config().page.home.components.heroBanner.data.imageSrc
  }
}
