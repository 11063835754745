import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { Component, Input, OnInit, inject, DestroyRef } from '@angular/core'
import { FilterDirective } from '../filter.directive'
import { FilterService } from 'src/app/core/services/filter.service'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { SearchService } from 'src/app/core/services/search.service'
import { filter, Subject } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { ChipsService } from 'src/app/core/services/chips.service'
import { EventService } from 'src/app/core/services/event.service'
import { IJsonLogic } from 'src/app/core/services/json-logic.service'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatSelectChange, MatSelectModule } from '@angular/material/select'

@Component({
  selector: 'app-location-filter',
  standalone: true,
  template: `
    <div class="location-container">
      <div class="search-button">
        <button mat-stroked-button (click)="getGeoLocation()"
          [attr.data-qa-id]="'filterLocationGeolocation' + filterId">
          <mat-icon class="material-icons-outlined">location_on</mat-icon>
          Search near me
        </button>
      </div>
      <div class="zipcode">
        <mat-form-field appearance="outline">
          <mat-label>Zip Code</mat-label>
          <input
            [attr.data-qa-id]="'filterLocationZipCode' + filterId"
            matInput
            type="text"
            [ngModel]="zipcode"
            pattern="[a-zA-Z0-9]{5}"
            (change)="processZipCode($event)">
        </mat-form-field>
      </div>
      <div class="miles">
        <span class="within">Within</span>
        <span class="selector">
          <mat-form-field class="select-outlined">
            <mat-select [(ngModel)]="miles" (selectionChange)="handleChange()"
              [attr.data-qa-id]="'filterLocationMiles' + filterId">
              <mat-option [value]="50">50 Miles</mat-option>
              <mat-option [value]="100">100 Miles</mat-option>
              <mat-option [value]="200">200 Miles</mat-option>
              <mat-option [value]="250">250 Miles</mat-option>
              <mat-option [value]="500">500 Miles</mat-option>
            </mat-select>
          </mat-form-field>
        </span>
      </div>
    </div>
  `,
  styleUrls: ['./location-filter.component.scss'],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class LocationFilterComponent extends FilterDirective implements OnInit {
  private _destroyRef = inject(DestroyRef)
  private _filterService = inject(FilterService)
  private _searchService = inject(SearchService)
  private _chipsService = inject(ChipsService)
  private _eventService = inject(EventService)
  zipcode?: string
  latlong?: [number, number]
  miles: number = 500

  ngOnInit(): void {
    this.getStateFilterValue()
    this._eventService.chipRemoved$.pipe(
      takeUntilDestroyed(this._destroyRef),
      filter(chip => chip.filterId === this.filterId),
    ).subscribe(() => this.clearAll())

    this._eventService.allChipsRemoved$.pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(() => this.clearAll())
  }

  clearAll() {
    delete this.zipcode
    delete this.latlong
    this.miles = 500
    this._chipsService.removeChip(this.filterId)
    this._searchService.handleFilterUpdated(this.filterId, null)
  }

  getStateFilterValue(): void {
    const state = this._filterService.getFilterState(this.filterId)?.state as { type: 'latlong' | 'zipcode', value: any, miles: number }
    if (state) {
      switch (state.type) {
        case 'latlong':
          this.latlong = state.value
          break
        case 'zipcode':
          this.zipcode = state.value
          break
      }
      this.miles = state.miles
      this.handleChange()
    }
  }

  getGeoLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        this.latlong = [position.coords.latitude, position.coords.longitude]
        delete this.zipcode
        this.handleChange()
      })
    }
  }

  processZipCode($event: Event): void {
    const target = $event.target as HTMLInputElement
    if (target.checkValidity() || !!target.value) {
      this.zipcode = target.value
      delete this.latlong
      this.handleChange()
    }
  }

  handleChange(): void {
    this._chipsService.removeChip(this.filterId)
    if (this.latlong || this.zipcode) {
      if (this.latlong) {
        this._chipsService.addChip({
          id: this.filterId,
          label: `My location - ${this.miles} miles`,
          filterId: this.filterId,
        })
        this._searchService.handleFilterUpdated(
          this.filterId,
          { type: 'latlong', value: this.latlong, miles: this.miles },
        )
      } else {
        this._chipsService.addChip({
          id: this.filterId,
          label: `${this.zipcode} - ${this.miles} miles`,
          filterId: this.filterId,
        })
        this._searchService.handleFilterUpdated(
          this.filterId,
          { type: 'zipcode', value: this.zipcode, miles: this.miles },
        )
      }
    } else {
      this._searchService.handleFilterUpdated(
        this.filterId,
        null,
      )
    }
  }

  static buildFilter(
    filterService: FilterService,
    miles: number,
    parentId: string,
  ): IJsonLogic {
    return filterService.build(({ variable, lte }) => {
      return lte(variable(parentId), miles)
    })
  }

  static buildQueryParams(
    type: 'latlong' | 'zipcode',
    value: any,
  ): {[key: string]: string} {
    return type === 'latlong' ? { lat: value[0], long: value[1] } : { postal_code: value }
  }
}
