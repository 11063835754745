import { CommonModule } from '@angular/common'
import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { IInputDialogOptions } from 'src/app/core/services/dialog.service'
import { SelectableOnOpenDirective } from 'src/app/core/directives/selectable-on-open.directive'
import { ButtonModule } from 'src/app/components/button/button.module'


@Component({
  selector: 'admin-input-dialog-component',
  standalone: true,
  template: `
    @if (data.title) {
      <div mat-dialog-title class="dialog__title">{{data.title}}</div>
    }
    <mat-dialog-content class="dialog__content">
      @for (input of data.inputs; track input; let last = $last) {
        @if (input.type === 'text') {
          <mat-form-field [class.no-padding]="last">
            @if (input.label) {
              <mat-label>{{input.label}}</mat-label>
            }
            <input
              matInput
              type="text"
              selectableOnOpen
              [selectOnOpen]="!!input.selectedOnOpen"
              [defaultValue]="input.defaultValue"
              (change)="input.change($event); validate();"
              (keyup)="input.change($event); validate();"
              (keydown.enter)="submitOnEnter()" />
          </mat-form-field>
        }
      }
    </mat-dialog-content>
    <mat-dialog-actions class="dialog__actions">
      <app-button btn-medium btn-primary btn-outlined mat-dialog-close [mat-dialog-close]="'cancel'">{{data.cancelLabel}}</app-button>
      <app-button btn-medium btn-primary btn-contained mat-dialog-close [mat-dialog-close]="'confirm'" [disabled]="!submitIsEnabled">{{data.confirmLabel}}</app-button>
    </mat-dialog-actions>
  `,
  styleUrls: ['../../../../assets/admin.scss'],
  styles: [`
    :host {
      display: block;
    }

    .no-padding {
      margin-bottom: -1.25em !important;
    }
  `],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    SelectableOnOpenDirective,
    ButtonModule,
  ],
})
export class AdminInputDialogComponent {
  submitIsEnabled: boolean = true

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IInputDialogOptions,
    public dialogRef: MatDialogRef<AdminInputDialogComponent>
  ) {}

  validate(): void {
    if (this.data.validate) {
      this.submitIsEnabled = this.data.validate()
    }
  }

  submitOnEnter(): void {
    if (!this.submitIsEnabled) {
      return
    }
    this.dialogRef.close('confirm')
  }
}
