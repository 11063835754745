import { WritableSignal } from '@angular/core'
import { IConfig } from '../services/config.service'
import { IConversionProcessor } from './processors'

export const processV1toV2: IConversionProcessor = {
  endVersion: '2',
  process(config: WritableSignal<IConfig>): void {
    updateHeaderLogoKey(config)
    updateHeaderLogoDisplay(config)
  }
}

const updateHeaderLogoKey = (config: WritableSignal<IConfig>) => {
  if (config().header?.data?.logo?.url) {
    config().header.data.logo.src = config().header.data.logo.url
    delete config().header.data.logo.url
  }
}

const updateHeaderLogoDisplay = (config: WritableSignal<IConfig>) => {
  if (config().header?.data?.logo?.src || config().header?.data?.imageToggle) {
    config().header.data.logo.display = true
  }
  if (config().header?.data?.imageToggle) {
    delete config().header.data.imageToggle
  }
}
