import { DestroyRef, inject } from '@angular/core'
import { FetchService } from './fetch.service'
import { Injectable } from '@angular/core'
import { ApiRequestService } from './api-request.service'
import { LogService } from './log.service'
import { EnvironmentService } from './environment.service'
import { HttpErrorResponse } from '@angular/common/http'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { environment } from 'src/environments/environment'
import { ClientService } from './client.service'
import { ToastService } from './toast.service'
import { EventService } from './event.service'

export interface IMediaData {
  kb: number,
  name: string,
  originalFileName: string,
  updated: string,
  updatedFormatted: string,
  width: number,
  height: number,
  urls: {
    [key: string]: string
  }
}

export interface IMediaLibraryResponse {
  results: IMediaData[]
}

export interface IFileUploadResponse {
  filename: string
  success: boolean
}

export interface IDeleteResponse {
  success: boolean
  deleted_files: string[],
  failed_to_delete: [
    {
      name: string,
      error: string,
      error_code: number
    }?
  ],
}

export enum ImageSizes {
  Thumbnail = '250x0xc',
  Full = '0x0xc',
  Unsized = 'unsized',
  Favicon = '32x32xc',
  DefaultProductImage = '648x486xc',
  SocialMediaShare = '1200x630xc',
  HeroBannerAspectRatio = '2000x0xc',
  HeroBannerSmall = '2000x800xc',
  HeroBannerMedium = '2000x1500xc',
  HeroBannerLarge = '2000x1800xc',
}

export const EMPTY_MEDIA_DATA: IMediaData = {
  kb: 0,
  name: '',
  originalFileName: '',
  updated: '',
  updatedFormatted: '',
  width: 100,
  height: 100,
  urls: {
    full: '',
    thumbnail: '',
    unsized: '',
  },
}

@Injectable()
export class MediaLibraryService {
  private destroyRef = inject(DestroyRef)

  constructor(
    protected fetchService: FetchService,
    protected logger: LogService,
    protected apiRequestService: ApiRequestService,
    protected envService: EnvironmentService,
    protected clientService: ClientService,
    protected toastService: ToastService,
    protected eventService: EventService,
  ) { }

  async getMediaLibrary(): Promise<IMediaData[]> {
    this.logger.debug('Getting media library...')
    if (this.envService.get().useMock) {
      this.logger.info(`Mock enabled, not actually getting media library.`)
      return Promise.resolve([{
        kb: 100,
        name: 'test.jpg',
        originalFileName: 'test.jpg',
        updated: '2021-08-01',
        updatedFormatted: '2021-08-01',
        width: 100,
        height: 100,
        urls: {
          thumbnail: '/assets/images/hero-image.jpg',
          full: '/assets/images/hero-image.jpg',
          unsized: '/assets/images/hero-image.jpg',
          [ImageSizes.Favicon]: '/assets/images/hero-image.jpg',
        }
      }])
    }
    const requestOptions = await this.apiRequestService.buildRequestOptions({
      headers: { authorized: true, customHeaders: { 'client-code': this.clientService.client().code } }
    })
    return new Promise((resolve, reject) => {
      this.fetchService.getRequest<IMediaLibraryResponse>(`${environment.image.api}/images`, undefined, requestOptions)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((response) => {
          if (response instanceof HttpErrorResponse) {
            this.logger.error('Unable to get media library.  ', response)
            reject({ userMessage: 'Unable to get media library.', ...response })
          } else {
            this.logger.info(`Media library recieved.`)
            const clientCode = this.clientService.client().code
            const responseData = response.results.map((result) => {
              const fileName = result.name.split('.').slice(0, -1).join('.')
              const fileExt = result.name.split('.').pop()
              const hash = result.name.match(/-\w{12}/)
              return {
                kb: result.kb,
                name: result.name,
                originalFileName: hash ? result.name.replace(hash[0], '') : result.name,
                updated: result.updated,
                width: result.width,
                height: result.height,
                updatedFormatted: new Date(result.updated).toLocaleDateString('en-US'),
                urls: {
                  thumbnail: `${environment.image.api}/${clientCode}/${fileName}.168x122xc.${fileExt}`,
                  full: `${environment.image.api}/${clientCode}/${fileName}.${ImageSizes.Full}.${fileExt}`,
                  unsized: `${environment.image.api}/source_images/${clientCode}/${fileName}.${fileExt}`,
                  [ImageSizes.Favicon]: `${environment.image.api}/${clientCode}/${fileName}.${ImageSizes.Favicon}.${fileExt}`,
                  [ImageSizes.Thumbnail]: `${environment.image.api}/${clientCode}/${fileName}.${ImageSizes.Thumbnail}.${fileExt}`,
                  [ImageSizes.DefaultProductImage]: `${environment.image.api}/${clientCode}/${fileName}.${ImageSizes.DefaultProductImage}.${fileExt}`,
                  [ImageSizes.SocialMediaShare]: `${environment.image.api}/${clientCode}/${fileName}.${ImageSizes.SocialMediaShare}.${fileExt}`,
                  [ImageSizes.HeroBannerAspectRatio]: `${environment.image.api}/${clientCode}/${fileName}.${ImageSizes.HeroBannerAspectRatio}.${fileExt}`,
                  [ImageSizes.HeroBannerSmall]: `${environment.image.api}/${clientCode}/${fileName}.${ImageSizes.HeroBannerSmall}.${fileExt}`,
                  [ImageSizes.HeroBannerMedium]: `${environment.image.api}/${clientCode}/${fileName}.${ImageSizes.HeroBannerMedium}.${fileExt}`,
                  [ImageSizes.HeroBannerLarge]: `${environment.image.api}/${clientCode}/${fileName}.${ImageSizes.HeroBannerLarge}.${fileExt}`,
                }
              }
            })
            return resolve(responseData)
          }
        })
    })
  }

  async uploadFile(file: File): Promise<string> {
    this.logger.debug(`Uploading file: `, file)
    if (this.envService.get().useMock) {
      this.logger.info(`Mock enabled, not actually uploading uploading file`)
      return Promise.resolve('/assets/images/hero-image.jpg')
    }
    const formData = new FormData()
    const requestOptions = await this.apiRequestService.buildRequestOptions({
      headers: { authorized: true, customHeaders: { 'client-code': this.clientService.client().code } }
    })
    formData.append('file', file)
    return new Promise((resolve, reject) => {
      this.fetchService.postRequest<IFileUploadResponse>(`${environment.image.api}/upload`, formData, requestOptions)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((response) => {
          if (response instanceof HttpErrorResponse) {
            this.logger.error('Unable to upload file.  ', response)
            this.eventService.notifyMediaLibraryFileUploadFailed({ originalFileName: file.name })
            reject({ userMessage: 'Unable to upload file.', ...response })
          } else {
            this.logger.info(`File uploaded.`)
            this.eventService.notifyMediaLibraryFileUploaded({
              originalFileName: file.name,
              fileName: response.filename
            })
            return resolve(response.filename)
          }
        })
    })
  }

  async deleteFiles(files: string[]): Promise<IDeleteResponse> {
    this.logger.debug(`Deleting files: `, files)
    if (this.envService.get().useMock) {
      this.logger.info(`Mock enabled, not actually deleting files`)
      return Promise.resolve({
        success: true,
        deleted_files: files,
        failed_to_delete: []
      })
    }
    const requestOptions = await this.apiRequestService.buildRequestOptions({
      headers: { authorized: true, customHeaders: { 'client-code': this.clientService.client().code } }
    })
    return new Promise((resolve, reject) => {
      this.fetchService.deleteRequest<IDeleteResponse>(`${environment.image.api}/images?files=${files.join(',')}`, requestOptions)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((response) => {
          if (response instanceof HttpErrorResponse) {
            for (const failed of response.error.failed_to_delete ) {
              if (!failed) {
                continue
              }
              this.toastService.error({
                message: `Failed to delete ${failed.name}: ${failed.error}`,
              })
            }
            this.logger.error('Unable to delete files.  ', response)
            reject({ userMessage: 'Unable to delete files.', ...response })
          } else {
            for (const deleted of response.deleted_files) {
              const hash = deleted.match(/-\w{12}/)
              this.toastService.success({
                message: `Deleted ${ hash ? deleted.replace(hash[0], '') : deleted}`,
              })
            }
            this.logger.info(`Files deleted.`)
            return resolve(response)
          }
        })
    })
  }

  getImageSrcSet(imageSrcData: IMediaData): string {
    return Object.keys(imageSrcData.urls)
      .map((key) => `${imageSrcData.urls[key]} ${key.replace(/x.*/, 'w')}`)
      .join(', ')
  }

  getImageSizes(imageSrcData: IMediaData): string {
    return Object.keys(imageSrcData.urls)
      .map((key) => `(max-width: ${key.replace(/x.*/, 'px')}) ${key.replace(/x.*/, 'px')}`)
      .join(', ')
  }
}
