import { WritableSignal } from '@angular/core'
import { processV1toV2 } from './v1-to-v2'
import { processV2toV3 } from './v2-to-v3'
import { IConfig } from '../services/config.service'
import { LogService } from '../services/log.service'
import { processV3toV4 } from './v3-to-v4'


// increment when breaking changes to config are made
// and add a processor that will convert the old config into a new working one
export const currentConfigVersion = '4'
// ---------------------------------------------

export interface IConversionProcessor {
  endVersion: string
  process(
    config: WritableSignal<IConfig>,
    logger?: LogService,
  ): void
}

export interface IConversionProcessors {
  [startVersion: string]: IConversionProcessor
}

export const conversionProcessors: IConversionProcessors = {
  1: processV1toV2,
  2: processV2toV3,
  3: processV3toV4,
}
