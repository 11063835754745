import { DestroyRef, Injectable, inject } from '@angular/core'
import { LogService } from './log.service'
import { ConfigService, IAdminConfigData, IConfig } from './config.service'
import { AdminService } from './admin.service'
import { EnvironmentService } from './environment.service'
import { MockService } from './mock.service'
import { ApiRequestService } from './api-request.service'
import { ClientService, IClient } from './client.service'
import { FetchService } from './fetch.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { HttpErrorResponse } from '@angular/common/http'
import { lastValueFrom } from 'rxjs'
import { SeoService } from './seo.service'
import { FontService } from './font.service'
import { LanguageService } from './language.service'


export interface IApiAllConfigsResponse {
  client: IClient
  results: IAdminConfigData[]
}

export interface IApiConfigResponse {
  config: IConfig
  client: IClient
}

declare global {
  interface Window {
    configService: ConfigService;
    clientService: ClientService;
  }
}

@Injectable()
export class InitDataService {

  private _destroyRef = inject(DestroyRef)
  private _logger = inject(LogService)
  private _envService = inject(EnvironmentService)
  private _configService = inject(ConfigService)
  private _clientService = inject(ClientService)
  private _adminService = inject(AdminService)
  private _mockService = inject(MockService)
  private _apiRequestService = inject(ApiRequestService)
  private _fetchService = inject(FetchService)
  private _seoService = inject(SeoService)
  private _fontService = inject(FontService)
  private _languageService = inject(LanguageService)

  async initForAdmin(): Promise<void> {
    this._logger.debug(`Initializing admin data`)
    return this.loadAdminData().catch(() => {
      this._logger.error('Unable to load init admin data, disabling admin and loading normal user data.')
      this._adminService.disableAdmin()
      return this.initForUser()
    }).then(async () => {
      this._logger.debug(`Admin data initialized`)
      await this._configService.loadConfigForAdmin()
      return Promise.resolve()
    })
  }

  async initForUser(): Promise<void> {
    this._logger.debug(`Initializing user data`)
    return this.loadUserData().catch(() => {
      return Promise.reject()
    }).then(async () => {
      this._logger.debug('User data initialized')
      return Promise.resolve()
    })
  }

  initForEmbeddedUser(): void {
    this._logger.debug(`Initializing embedded user data`)
    this.loadEmbeddedUserData()
  }

  async loadAdminData(): Promise<void> {
    if (this._envService.get().useMock) {
      this._logger.debug('Mock enabled, loading mock configs.')
      this._configService.loadAdminConfigs(this._mockService.getAllConfigs().results)
      this._clientService.load(this._mockService.getMockClient())
      return Promise.resolve()
    }
    const requestOptions = await this._apiRequestService.buildRequestOptions({ headers: { authorized: true } })
    const endpoint = `${this._apiRequestService.buildEndpoint('admin/configurations/webshop')}`
    const source$ = this._fetchService.getRequest<IApiAllConfigsResponse>(endpoint, undefined, requestOptions).pipe(takeUntilDestroyed(this._destroyRef))
    const response = await lastValueFrom(source$)
    if (response instanceof HttpErrorResponse) {
      this._logger.error('Unable to load configs for client.  ', response)
      return Promise.reject()
    }
    this._configService.loadAdminConfigs(response.results)
    this._fontService.init()
    this._clientService.load(response.client)
    this._logger.debug(`Admin data loaded: `, response)
    return Promise.resolve()
  }

  async loadUserData(): Promise<void> {
    if (this._envService.get().useMock) {
      this._logger.debug('Mock enabled, loading mock config.')
      this._configService.loadConfig(this._mockService.getMockConfig())
      this._clientService.load(this._mockService.getMockClient())
      return Promise.resolve()
    }
    const endpoint = `${this._apiRequestService.buildEndpoint('configuration/webshop')}`
    const source$ = this._fetchService.getRequest<IApiConfigResponse>(endpoint).pipe(takeUntilDestroyed(this._destroyRef))
    const response = await lastValueFrom(source$)
    if (response instanceof HttpErrorResponse) {
      this._logger.error('Unable to load live config.  ', response)
      return Promise.reject()
    }
    this._configService.loadConfig(response.config)
    this._fontService.init()
    this._clientService.load(response.client)
    if (this._envService.get().clientsBlockRobots.includes(response.client.code)) {
      this._logger.debug('Client is blocked, blocking robots.')
      this._seoService.blockRobots()
    } else {
      this._seoService.allowRobots()
    }
  }

  loadEmbeddedUserData(): void {
    this._configService.config.set(window.parent.configService.config())
    this._clientService.client.set(window.parent.clientService.client())
    this._fontService.init()
  }

}
