import { Injectable, inject } from '@angular/core'
import { LanguageCode } from '../interfaces/language.interface'
import { LanguageService } from './language.service'


export type I18nText = Record<LanguageCode, string>

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  private _langService = inject(LanguageService)

  get(text: Partial<I18nText> | string | undefined) {
    if (typeof text === 'string' || !text) {
      return text ?? ''
    }
    let str = text[this._langService.selectedLanguage().code]
    if (!str) {
      str = text[this._langService.getDefaultLanguage()]
    }
    if (!str) {
      str = ''
    }
    return str
  }
}
