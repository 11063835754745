import { Injectable, inject } from '@angular/core'
import { LogService } from './log.service'
import { EnvironmentService } from './environment.service'


export enum LegacyImageProcessorTypes {
  Normal = 'Normal',
  Thumbnail = 'Thumbnail',
  HertzThumbnail = 'HertzThumbnail',
  URIFeaturedThumb = 'URIFeaturedThumb',
  FeaturedThumb = 'FeaturedThumb',
  FeaturedItem = 'FeaturedItem',
  Proxi = 'Proxi',
  ItemDetailBig = 'ItemDetailBig',
  ItemDetailLarge = 'ItemDetailLarge',
  ItemDetailExtended = 'ItemDetailExtended',
  ItemDetailExpanded = 'ItemDetailExpanded',
  ItemDetailZoom = 'ItemDetailZoom',
  CarouselItem = 'CarouselItem',
}

export interface ILegacyImageProcessorOptions {
  guid?: string | string[]
  transparent?: boolean
  type?: LegacyImageProcessorTypes
}

export const defaultLegacyImageProcessorOptions = {
  guid: 'none',
  transparent: false,
  type: LegacyImageProcessorTypes.CarouselItem,
}

@Injectable()
export class ImageService {
  private _legacyApi: string = inject(EnvironmentService).get().image.legacyApi

  buildLegacyProcessorSrc(options: ILegacyImageProcessorOptions): string {
    const {
      transparent = defaultLegacyImageProcessorOptions.transparent,
      type = defaultLegacyImageProcessorOptions.type,
    } = options
    let {
      guid = defaultLegacyImageProcessorOptions.guid,
    } = options
    if (Array.isArray(guid)) {
      guid = guid[0]
    }
    return `${this._legacyApi}`
      + `?guid=${guid}`
      + `&type=${type}`
      + `&transparent=${transparent}`
  }
}
