import { Directive, Input, inject } from '@angular/core'
import { FilterId, FilterService, IFilterItem } from 'src/app/core/services/filter.service'


@Directive({
  selector: 'app-filter',
  standalone: true,
})
export abstract class FilterDirective {
  protected filterService = inject(FilterService)
  @Input() filterId!: FilterId
  @Input() settingsId!: string // the key in the filter settings passed back from the api
  get settings(): IFilterItem[] {
    return this.filterService.filterSettings[this.settingsId]
  }
}
