import { Injectable } from '@angular/core'


export interface IJsonLogic {
  and?: IJsonLogic[]
  or?: IJsonLogic[]
  in?: IJsonLogic[]
  '=='?: (IJsonLogic | string)[]
  '!='?: (IJsonLogic | string)[]
  '<'?: (IJsonLogic | number)[]
  '<='?: (IJsonLogic | number)[]
  '>'?: (IJsonLogic | number)[]
  '>='?: (IJsonLogic | number)[]
  var?: string
}

@Injectable({
  providedIn: 'root'
})
export class JsonLogicService {
  and = (...commands: any): IJsonLogic => ({ and: [...commands] })
  or = (...commands: any): IJsonLogic => ({ or: [...commands] })
  inArray = (...commands: any): IJsonLogic => ({ in: [...commands] })
  var = (key: string): IJsonLogic => ({ var: key })
  equals = (...commands: any): IJsonLogic => ({ '==': [...commands] })
  doesNotEqual = (...commands: any): IJsonLogic => ({ '!=': [...commands] })
  lt = (...commands: any): IJsonLogic => ({ '<': [...commands] })
  lte = (...commands: any): IJsonLogic => ({ '<=': [...commands] })
  gt = (...commands: any): IJsonLogic => ({ '>': [...commands] })
  gte = (...commands: any): IJsonLogic => ({ '>=': [...commands] })
}
