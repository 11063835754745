import { DOCUMENT } from '@angular/common'
import { DestroyRef, inject, Injectable } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { debounceTime } from 'rxjs'
import { IFont } from '../interfaces/font.interface'
import { ConfigService } from './config.service'
import { EventService } from './event.service'
import { KeysService } from './keys.service'
import { LogService } from './log.service'

export enum IFontMimeType {
  TTF = 'truetype',
  WOFF = 'woff',
  WOFF2 = 'woff2',
  OTF = 'otf',
}
@Injectable()
export class FontService {
  private _document = inject(DOCUMENT)
  private _eventService = inject(EventService)
  private _configService = inject(ConfigService)
  private _keysService = inject(KeysService)
  private _destroyRef = inject(DestroyRef)
  private _fonts: Array<IFont> = []
  private _url: string = ''
  private _headingsFont?: IFont
  private _bodyFont?: IFont
  private _logger = inject(LogService)

  constructor() {

    this._eventService.configUpdated$.pipe(
      takeUntilDestroyed(this._destroyRef),
      debounceTime(200),
    ).subscribe(() => {
      const config = this._configService.config()
      if (config.theme.fonts.headingsFontFamily?.family !== this._headingsFont?.family ||
          config.theme.fonts.bodyFontFamily?.family !== this._bodyFont?.family) {
        this._headingsFont = config.theme.fonts.headingsFontFamily
        this._bodyFont = config.theme.fonts.bodyFontFamily
        this.setFonts()
      }
    })
  }

  async getFonts(name?: string, limit = 5): Promise<Array<IFont>> {
    this._logger.debug('FontService.getFonts()')

    if (!this._url) {
      this._logger.debug('FontService.getFonts() - No key found')
      return []
    }

    if (this._fonts.length === 0) {
      try {
        const response = await fetch(this._url)
        this._fonts = (await response.json()).items
      } catch (e) {
        this._logger.error(`Failed to fetch google fonts catalog `, e)
      }
    }
    return (name ? this._fonts.filter(font => font.family.toLowerCase().includes(name.toLowerCase())) : this._fonts).slice(0, limit)
  }

  init(): void {
    this._logger.debug('FontService.init()')


    setTimeout(() => {
      const keys = this._keysService.keys()

      if (!keys.google_webfont_key) {
        this._logger.debug('FontService.init() - No key found')
      } else {
        this._url = `https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=${keys.google_webfont_key}`
      }

      const config = this._configService.config()
      this._headingsFont = config.theme.fonts.headingsFontFamily
      this._bodyFont = config.theme.fonts.bodyFontFamily
      this.setFonts()
    })
  }

  setFonts(): void {
    this._logger.debug('FontService.setFonts()')
    if (this._headingsFont) {
      this._document.getElementById('headingsFont')
        ?.setAttribute('href', this.buildFontUrl(this._headingsFont))
    }
    if (this._bodyFont) {
      this._document.getElementById('bodyFont')
        ?.setAttribute('href', this.buildFontUrl(this._bodyFont))
    }
  }

  buildFontUrl(font: { family: string }): string {
    this._logger.debug('FontService.buildFontUrl()')
    const url = []
    url.push('https://fonts.googleapis.com/css?family=')
    url.push(font.family.replace(/ /g, '+'))
    url.push(':wght@300;400;500;600;700')

    return url.join('')
  }
}
