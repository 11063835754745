import { Injectable, inject } from '@angular/core'
import { ClientService } from './client.service'
import { environment } from 'src/environments/environment'
import { Router } from '@angular/router'
import { Firestore, doc, onSnapshot } from '@angular/fire/firestore'
import { Observable } from 'rxjs'

interface IMaintenanceDocument {
    clientCodes: string[]
    excludeClientCodes: boolean
    forceExecute: boolean
    includeWebshops: boolean
    is_in_maintenance: boolean
    limitToClientCodes: boolean
    triggerSignOut: boolean
    turnOnWalls: boolean
    webshopOnly: boolean
}

@Injectable()
export class MaintenanceGuardService {
  private _clientService = inject(ClientService)
  private _router: Router = inject(Router)
  private _firestore: Firestore = inject(Firestore)

  firestoreDocs: Observable<any[]> = new Observable()

  canActivate(): boolean {
    onSnapshot(doc(this._firestore, 'maintenance', environment.firebaseEnv), (maintenanceDoc) => {
      const clientCode = this._clientService.getClientCode().toLocaleLowerCase()
      if (!clientCode) {
        return true
      }
      const document = maintenanceDoc.data() as IMaintenanceDocument
      if (!document)  {
        return true
      }
      const clientCodes = document.clientCodes.map((code: string) => code.toLocaleLowerCase())
      if (document.turnOnWalls && document.includeWebshops || document.webshopOnly) {
        if (document.excludeClientCodes && (clientCodes.includes(clientCode) || clientCodes.includes('ALL_CLIENTS'))) {
          return
        } else if (document.excludeClientCodes && !clientCodes.includes(clientCode)) {
          this._router.navigate(['/maintenance'])
          return
        } else if (document.limitToClientCodes && clientCodes.includes(clientCode)) {
          this._router.navigate(['/maintenance'])
          return
        } else if (document.limitToClientCodes && !clientCodes.includes(clientCode)) {
          return
        } else {
          this._router.navigate(['/maintenance'])
          return
        }
      } else if (document.turnOnWalls === false && window.location.pathname === '/maintenance') {
        this._router.navigate(['/'])
        return
      }
      return
    })
    return true
  }
}



