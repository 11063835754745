import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { Component, Input, OnInit, inject, DestroyRef } from '@angular/core'
import { FilterDirective } from '../filter.directive'
import { FilterService } from 'src/app/core/services/filter.service'
import { MatSliderModule } from '@angular/material/slider'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { SearchService } from 'src/app/core/services/search.service'
import { debounceTime, filter, Subject } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { ChipsService } from 'src/app/core/services/chips.service'
import { EventService } from 'src/app/core/services/event.service'
import { IJsonLogic } from 'src/app/core/services/json-logic.service'
import { AssetPricePipe } from 'src/app/core/pipes/asset-price.pipe'
import { CurrencyService } from 'src/app/core/services/currency.service'
import { CurrencyCode } from 'src/app/core/interfaces/currency.interface'
import { PriceService } from 'src/app/core/services/price.service'


interface IRangeFilterState {
  range: number[]
  includeLow: boolean
}

@Component({
  selector: 'app-range-filter',
  standalone: true,
  template: `
    <div class="range-container">
      <div class="input-boxes">
        <mat-form-field appearance="outline">
          <mat-label>Min</mat-label>
          @if (this.isCurrencyField) {
            <span class="currency" matPrefix>{{currencyService.selectedCurrency().symbol}}</span>
          }
          <input
            [attr.data-qa-id]="'filterRangeMin' + filterId"
            matInput
            type="number"
            [(ngModel)]="selectedRange[0]"
            (change)="handleChange()">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Max</mat-label>
          @if (this.isCurrencyField) {
            <span class="currency" matPrefix>{{currencyService.selectedCurrency().symbol}}</span>
          }
          <input
            [attr.data-qa-id]="'filterRangeMax' + filterId"
            matInput
            type="number"
            [(ngModel)]="selectedRange[1]"
            (change)="handleChange()">
        </mat-form-field>
      </div>
      <div class="slider">
        <mat-slider [min]="minMaxValues[0]" [max]="minMaxValues[1]" [step]="1">
          <input
            matSliderStartThumb
            [(ngModel)]="selectedRange[0]"
            (change)="selectedRangeSubject.next()">
          <input
            matSliderEndThumb
            [(ngModel)]="selectedRange[1]"
            (change)="selectedRangeSubject.next()">
        </mat-slider>
      </div>
      @if (this.isCurrencyField) {
        <div class="minmax">
          <span class="min">{{ minMaxValues[0] | currency : currencyService.selectedCurrency().code : 'symbol-narrow' : '1.0-0' }}</span>
          <span class="max">{{ minMaxValues[1] | currency : currencyService.selectedCurrency().code : 'symbol-narrow' : '1.0-0' }}</span>
        </div>
      }
      @if (!this.isCurrencyField) {
        <div class="minmax">
          <span class="min">{{ minMaxValues[0] }}</span>
          <span class="max">{{ minMaxValues[1] }}</span>
        </div>
      }
    </div>
  `,
  styleUrls: ['./range-filter.component.scss'],
  imports: [
    CommonModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    AssetPricePipe,
  ],
})
export class RangeFilterComponent extends FilterDirective implements OnInit {
  private _destroyRef = inject(DestroyRef)
  private _filterService = inject(FilterService)
  private _searchService = inject(SearchService)
  private _chipsService = inject(ChipsService)
  private _eventService = inject(EventService)
  private _priceService = inject(PriceService)
  currencyService = inject(CurrencyService)
  @Input() filterTitle: string = ''
  @Input() isCurrencyField: boolean = false
  @Input() minValue: number = 0
  @Input() maxValue: number = 1000000
  @Input() includeLow: boolean = true // use gte vs gt in json logic for low range

  selectedRangeSubject: Subject<void> = new Subject()
  selectedRange: [number, number] = [0,0]
  minMaxValues!: [number, number]

  ngOnInit(): void {
    this.init()
    this.loadFilterValues()
    this.selectedRangeSubject.pipe(
      takeUntilDestroyed(this._destroyRef),
      debounceTime(200),
    ).subscribe(() => this.processStateChange())

    if (this.isCurrencyField) {
      this._eventService.currencySelected$.pipe(
        takeUntilDestroyed(this._destroyRef),
      ).subscribe(() => {
        this.init()
        this.loadFilterValues()
      })
    }

    this._eventService.chipRemoved$.pipe(
      takeUntilDestroyed(this._destroyRef),
      filter(chip => chip.filterId === this.filterId),
    ).subscribe(() => this.clearAll())

    this._eventService.allChipsRemoved$.pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(() => this.clearAll())
  }

  clearAll() {
    this.selectedRange = [...this.minMaxValues]
    this._chipsService.removeChip(this.filterId)
    this._searchService.handleFilterUpdated(this.filterId, null)
  }

  init() {
    this.minMaxValues = [this.minValue, Math.ceil(this.maxValue)]
    this.selectedRange = [...this.minMaxValues]
    if (this.isCurrencyField) {
      this.minMaxValues = this.minMaxValues.map((value: number) => this.adjustCurrencyValue(value)) as [number, number]
      this.selectedRange = this.selectedRange.map((value: number) => this.adjustCurrencyValue(value)) as [number, number]
    }
  }

  loadFilterValues() {
    if (this._filterService.getFilterState(this.filterId)?.state) {
      const range = (this._filterService.getFilterState(this.filterId)?.state as IRangeFilterState).range
      if (this.isCurrencyField) {
        this.selectedRange = range.map((value: number) => this.adjustCurrencyValue(value)) as [number, number]
      } else {
        this.selectedRange = range.map((value: number) => value) as [number, number]
      }
      this.processStateChange()
    }
  }

  adjustCurrencyValue(value: number) {
    return Math.round(this._priceService.calculatePriceForSelectedCurrency(
      value,
      value,
      CurrencyCode.USD,
      this.currencyService.selectedCurrency().code
    ).price)
  }

  handleChange() {
    if (this.selectedRange[0] === null) {
      this.selectedRange[0] = this.minMaxValues[0]
    }
    if (this.selectedRange[1] === null) {
      this.selectedRange[1] = this.minMaxValues[1]
    }
    this.selectedRangeSubject.next()
  }

  processStateChange(): void {
    const rangeDivisor = this.isCurrencyField ?
      this.currencyService.selectedCurrency().rate ?? 1
      : 1
    const range = (this.selectedRange[0] !== this.minMaxValues[0] || this.selectedRange[1] !== this.minMaxValues[1])
      ? this.selectedRange.map((value) => Math.round(value / rangeDivisor)) : null
    const state: IRangeFilterState | null = range ?
      { range, includeLow: this.includeLow }
      : null
    this._searchService.handleFilterUpdated(
      this.filterId,
      state,
    )

    const currency = this.isCurrencyField ? this.currencyService.selectedCurrency().symbol : ''

    this._chipsService.removeChip(this.filterId)
    if (state !== null) {
      this._chipsService.addChip({
        id: this.filterId,
        label: `${this.filterTitle}: ` +
          (this.selectedRange[0] === this.selectedRange[1] ? `${currency}${this.selectedRange[0]}` : `${currency}${this.selectedRange[0]} - ${currency}${this.selectedRange[1]}`),
        filterId: this.filterId,
      })
    }
  }

  static buildFilter(
    filterService: FilterService,
    state: IRangeFilterState,
    parentId: string,
  ): IJsonLogic {
    const { range, includeLow } = state
    return filterService.build(({ gt, gte, lte, and, variable }) => {
      let lowRangeJson = gt(variable(parentId), range[0])
      if (includeLow) {
        lowRangeJson = gte(variable(parentId), range[0])
      }
      return and(lowRangeJson, lte(variable(parentId), range[1]))
    })
  }
}
