import { Injectable, inject } from '@angular/core'
import { ConfigService } from './config.service'
import { ImageSizes } from './media-library.service'

@Injectable()
export class GeneralSettingsService {
  private _configService = inject(ConfigService)

  getDefaultImage(): string {
    return this._configService.config().general.data.default_img_data?.urls[ImageSizes.DefaultProductImage]
      || 'https://catalog-assets.rousesales.com/_default/content/no-photo2.png'
  }

}
