import { DestroyRef, Injectable, WritableSignal, inject, signal } from '@angular/core'
import { MockService } from './mock.service'
import { AdminService } from './admin.service'
import { LogService } from './log.service'
import { EventService } from './event.service'
import { debounceTime } from 'rxjs'
import { merge } from 'lodash-es'
import { FetchService } from './fetch.service'
import { ApiRequestService } from './api-request.service'
import { HttpErrorResponse } from '@angular/common/http'
import { ClientService } from './client.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { AuthService } from '@auth0/auth0-angular'
import { EnvironmentService } from './environment.service'
import { mockConfigMetadata } from '../mock-data/config.mock-data'
import { currentConfigVersion } from '../versions/processors'
import { CurrencyCode } from '../interfaces/currency.interface'
import { KeysService } from './keys.service'
import { IFont } from '../interfaces/font.interface'
import { IMediaData, ImageSizes } from './media-library.service'
import { RoutePaths } from 'src/app/app-routing.module'
import { LanguageCode } from '../interfaces/language.interface'
import { I18nText } from './i18n.service'
import { defaultDetailsPageSiteTitle, defaultHomePageSiteTitle, defaultSearchPageSiteTitle } from '../i18n/config-defaults'



export interface IComponentDataConfig {
  [key: string]: any
  styles: {
    [key: string]: {
      [key: string]: string
    }
  }
  components: {
    [key: string]: IComponentConfig
  }
}

export interface IComponentConfig {
  enabled: boolean
  template: string
  data: IComponentDataConfig
}

export interface ISitemap {
  static: string[]
  dynamic: {
    url: string
    id: string
    filters?: {
      [key: string]: any
    }[]
  }[]
}

export interface ISiteSeoConfig {
  data: ISiteSeoConfigData
}

export interface ISiteSeoConfigData {
  favicon_img_data?: IMediaData
  social_img_data?: IMediaData
  favicon_img?: string
  social_img?: string
}

export interface IPageSeoConfig {
  data: IPageSeoConfigData
}

export interface IPageSeoConfigData {
  title: I18nText | string
  description: string
}

export interface IGeneralConfig {
  data: {
    default_img?: string
    default_img_data: IMediaData
  }
}

export interface ICurrencyConfig extends IComponentConfig {
  available: CurrencyCode[]
  default: CurrencyCode
}
export interface ILanguageConfig {
  options: {
    base: {
      available: LanguageCode[]
      default: LanguageCode
    }
    [key: string]: {
      available: LanguageCode[]
      default: LanguageCode
    }
  }
  last_uploaded?: string
}

export interface IPageConfig {
  seo: IPageSeoConfig
  components: {
    [key: string]: IComponentConfig
  }
  terms?: {
    [key: string]: string
  }
}

export interface IConfig {
  version: string
  sitemap: ISitemap
  seo: ISiteSeoConfig
  general: IGeneralConfig
  currency: ICurrencyConfig
  language: ILanguageConfig
  theme: {
    id: string
    fonts: {
      [key: string]: IFont,
    }
    overrides: {
      [key: string]: string
    }
  }
  header: IComponentConfig
  footer: IComponentConfig
  legalTerms: IComponentConfig
  contactForm: IComponentConfig
  page: {
    [key: string]: IPageConfig
  }
}

export interface IApiNewRequest {
  config: IConfig
  name: string
  client_id: number
}

export interface IApiNewResponse extends IApiSaveResponse {
  catalog_id: number
}

export interface IApiSaveRequest extends IApiNewRequest {
  catalog_id: number
}

export interface IApiSaveResponse {
  success: boolean
  reason?: string
}

export interface IApiDeleteResponse {
  success: boolean
  reason?: string
}

export interface IApiRenameResponse {
  success: boolean
  reason?: string
}

export interface IConfigMetadata {
  id: number
  is_live: boolean
  name: string
  date_modified: string
}

export interface IAdminConfigData extends IConfigMetadata {
  config: IConfig
}

export enum ConfigSection {
  Seo = 'seo',
  General = 'general',
  Currency = 'currency',
  Theme = 'theme',
  Header = 'header',
  Footer = 'footer',
  LegalTerms = 'legalTerms',
  ContactForm = 'contactForm',
  Page = 'page',
}

declare global {
  interface Window { configService: ConfigService; }
}

@Injectable()
export class ConfigService {
  private destroyRef = inject(DestroyRef)
  private _config: WritableSignal<IConfig> = signal(this.create())
  private _configMetadata!: WritableSignal<IConfigMetadata>
  private _loadConfigIdKey = 'load-config-id'
  private _localConfigKey = 'config-in-progress'
  private _localConfigMetadataKey = 'config-metadata'
  private _localDirtyKey = 'config-dirty-state'
  initialized: boolean = false

  constructor(
    protected mockService: MockService,
    protected adminService: AdminService,
    protected logger: LogService,
    protected eventService: EventService,
    protected fetchService: FetchService,
    protected apiRequestService: ApiRequestService,
    protected clientService: ClientService,
    protected auth: AuthService,
    protected envService: EnvironmentService,
    protected keysService: KeysService,
  ) {
    window.configService = this
  }

  private _adminConfigs: IAdminConfigData[] = []

  get adminConfigs(): IAdminConfigData[] {
    return this._adminConfigs
  }

  loadAdminConfigs(configs: IAdminConfigData[]) {
    this._adminConfigs = configs
  }

  sortAdminConfigs(configs: IAdminConfigData[]) {
    return configs.sort((a, b) => {
      // Published config always comes first
      if (a.is_live) {
        return -1
      }
      if (b.is_live) {
        return 1
      }

      // Currently loaded config comes second
      if (a.id === this._configMetadata().id) {
        return -1
      }
      if (b.id === this._configMetadata().id) {
        return 1
      }

      // Everything else sort by date_modified in descending order
      return new Date(b.date_modified).getTime() - new Date(a.date_modified).getTime()
    })
  }

  initForAdmin(): void {
    this.eventService.configUpdated$.pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(200),
    ).subscribe(() => {
      this.saveLocally()
      this.markAsDirty()
    })
    this._adminConfigs = this.sortAdminConfigs(this._adminConfigs)
  }

  async save(): Promise<void> {
    this.logger.debug('Saving config: ', this._config())
    if (this.envService.get().useMock) {
      this.logger.debug('Mock enabled, not actually saving.')
      this.clearDirty()
      return Promise.resolve()
    }
    const requestOptions = await this.apiRequestService.buildRequestOptions({ headers: { authorized: true } })
    return new Promise((resolve, reject) => {
      this.fetchService.putRequest<IApiSaveResponse>(`${this.apiRequestService.buildEndpoint('admin/configuration')}`, {
        config: this._config(),
        client_id: this.clientService.client().id,
        catalog_id: this._configMetadata().id,
        name: this._configMetadata().name,
        is_live: this._configMetadata().is_live,
      } as IApiSaveRequest, requestOptions).pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(response => {
        if (response instanceof HttpErrorResponse) {
          this.logger.error('Unable to save config.  ', response)
          return reject('Backend returned an error.')
        }
        this.logger.info(`Config [${this._configMetadata().name}] saved.`)
        const fullConfigData = this._adminConfigs.find(c => c.id === this._configMetadata().id)
        if (fullConfigData) {
          fullConfigData.config = JSON.parse(JSON.stringify(this._config()))
          fullConfigData.date_modified = new Date().toString()
        }
        this.clearDirty()
        resolve()
      })
    })
  }

  saveLocally(config?: IConfig, configMetadata?: IConfigMetadata): void {
    this.logger.debug('Saving config locally: ', config ?? this._config())
    this.logger.debug('Saving config metadata locally: ', configMetadata ?? this._configMetadata())
    localStorage.setItem(this._localConfigKey, JSON.stringify(config ?? this._config()))
    localStorage.setItem(this._localConfigMetadataKey, JSON.stringify(configMetadata ?? this._configMetadata()))
    this.eventService.notifyConfigSavedLocally()
  }

  clearLocal(): void {
    localStorage.removeItem(this._localConfigKey)
    localStorage.removeItem(this._localConfigMetadataKey)
  }

  checkLocalExists(): boolean {
    return !!localStorage.getItem(this._localConfigKey)
  }

  checkLocalExistsInData(): boolean {
    return !!this._adminConfigs.find(c => c.id === this.configMetadata().id)
  }

  markAsDirty(): void {
    localStorage.setItem(this._localDirtyKey, 'true')
    this.eventService.notifyConfigDirtyChanged(true)
  }

  clearDirty(): void {
    localStorage.removeItem(this._localDirtyKey)
    this.eventService.notifyConfigDirtyChanged(false)
  }

  checkIsDirty(): boolean {
    return localStorage.getItem(this._localDirtyKey) === 'true'
  }

  reset(): void {
    this.logger.debug('Clearing local config.')
    this.loadConfigId = this.configMetadata().id
    this.clearLocal()
    this.clearDirty()
    window.location.reload()
  }

  async publish(): Promise<void> {
    this.logger.debug('Publishing config: ', this._config())
    if (this.envService.get().useMock) {
      this.logger.debug('Mock enabled, not actually publishing.')
      this.clearDirty()
      return Promise.resolve()
    }
    const requestOptions = await this.apiRequestService.buildRequestOptions({ headers: { authorized: true } })
    return new Promise((resolve, reject) => {
      this.fetchService.putRequest<IApiSaveResponse>(`${this.apiRequestService.buildEndpoint('admin/configuration')}`, {
        config: this._config(),
        client_id: this.clientService.client().id,
        catalog_id: this._configMetadata().id,
        name: this._configMetadata().name,
        is_live: true,
      }, requestOptions).pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(response => {
        if (response instanceof HttpErrorResponse) {
          this.logger.error('Unable to publish config.  ', response)
          return reject('Backend returned an error.')
        }
        this.logger.info(`Config [${this._configMetadata().name}] published.`)
        const fullConfigData = this._adminConfigs.find(c => c.id === this._configMetadata().id)
        if (fullConfigData) {
          fullConfigData.is_live = true
          this._adminConfigs.filter(c => c.id !== this._configMetadata().id).forEach(c => c.is_live = false)
        }
        this.saveLocally(this._config(), {
          ...this._configMetadata(),
          is_live: true,
        })
        this._configMetadata().is_live = true
        this.clearDirty()
        resolve()
      })
    })
  }

  async new(name: string): Promise<void> {
    this.logger.debug('Creating a new config.')

    if (this.envService.get().useMock) {
      this.logger.debug('Mock enabled, not actually creating new.')
      this.saveLocally(this.create(), {
        ...mockConfigMetadata,
        name,
      })
      this.clearDirty()
      return Promise.resolve()
    }
    if (name.trim() === '') {
      return Promise.reject('Name required')
    }
    // save new config and get config id
    const requestOptions = await this.apiRequestService.buildRequestOptions({ headers: { authorized: true } })
    return new Promise((resolve, reject) => {
      this.fetchService.putRequest<IApiNewResponse>(`${this.apiRequestService.buildEndpoint('admin/configuration')}`, {
        config: this.create(),
        name,
        client_id: this.clientService.client().id,
      } as IApiSaveRequest, requestOptions).pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(response => {
        if (response instanceof HttpErrorResponse) {
          this.logger.error('Unable to create config.  ', response)
          return reject(response.error)
        }
        if (!response.success) {
          this.logger.error('Creating config was not successful.  ', response.reason)
          return reject(response.reason)
        }
        this.logger.info(`Config [${name}] created.`)
        this.loadConfigId = response.catalog_id
        this.clearLocal()
        this.clearDirty()
        resolve()
      })
    })
  }

  async copy(configId: number, name: string): Promise<void> {
    this.logger.debug('Copying config at id [', configId ,']')
    const configToCopy = this._adminConfigs.find(c => c.id === configId)
    if (!configToCopy) {
      this.logger.error('Could not find config to copy at id: ', configId)
      return Promise.reject()
    }
    const configCopy = Object.assign({}, configToCopy, {
      name,
      is_live: false,
    })
    if (this.envService.get().useMock) {
      this.logger.debug('Mock enabled, not actually copying config.')
      const newConfig = {
        ...configCopy,
        catalog_id: Math.round(Math.random() * 100000),
      }
      this._adminConfigs.push(newConfig)
      return Promise.resolve()
    }
    const requestOptions = await this.apiRequestService.buildRequestOptions({ headers: { authorized: true } })
    return new Promise((resolve, reject) => {
      this.fetchService.putRequest<IApiNewResponse>(`${this.apiRequestService.buildEndpoint('admin/configuration')}`, {
        config: configCopy.config,
        name,
        client_id: this.clientService.client().id,
      }, requestOptions).pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(response => {
        if (response instanceof HttpErrorResponse) {
          this.logger.error('Unable to copy config.  ', response)
          return reject(response.error)
        }
        if (!response.success) {
          this.logger.error('Copying config was not successful.  ', response.reason)
          return reject(response.reason)
        }
        this.logger.info(`Config [${name}] created.`)
        configCopy.id = response.catalog_id
        this._adminConfigs.push(configCopy)
        resolve()
      })
    })
  }

  async rename(configId: number, name: string): Promise<void> {
    this.logger.debug('Renaming config at id [', configId ,']')
    const configToRename = this._adminConfigs.find(c => c.id === configId)
    if (!configToRename) {
      this.logger.error('Could not find config to rename at id: ', configId)
      return Promise.reject()
    }
    if (this.envService.get().useMock) {
      this.logger.debug('Mock enabled, not actually renaming config.')
      configToRename.name = name
      return Promise.resolve()
    }
    const requestOptions = await this.apiRequestService.buildRequestOptions({ headers: { authorized: true } })
    return new Promise((resolve, reject) => {
      this.fetchService.putRequest<IApiSaveResponse>(`${this.apiRequestService.buildEndpoint('admin/configuration')}`, {
        config: { ...configToRename.config },
        client_id: this.clientService.client().id,
        catalog_id: configToRename.id,
        name,
      }, requestOptions).pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(response => {
        if (response instanceof HttpErrorResponse) {
          this.logger.error('Unable to rename config.  ', response)
          return reject(response.error)
        }
        if (!response.success) {
          this.logger.error('Renaming config was not successful.  ', response.reason)
          return reject(response.reason)
        }
        this.logger.info(`Config [${name}] renamed.`)
        configToRename.name = name
        if (this.configMetadata().id === configToRename.id) {
          this.configMetadata().name = name
          this.saveLocally(configToRename.config, this.configMetadata())
        }
        resolve()
      })
    })
  }


  async delete(configId: number): Promise<void> {
    this.logger.debug('Deleting config at id [', configId ,']')
    const configToDelete = this._adminConfigs.find(c => c.id === configId)
    if (!configToDelete) {
      this.logger.error('Could not find config to delete at id: ', configId)
      return Promise.reject()
    }
    if (this.envService.get().useMock) {
      this.logger.debug('Mock enabled, not actually deleting config.')
      this._adminConfigs = this._adminConfigs.filter(c => c.id !== configId)
      return Promise.resolve()
    }
    const requestOptions = await this.apiRequestService.buildRequestOptions({ headers: { authorized: true } })
    return new Promise((resolve, reject) => {
      this.fetchService.deleteRequest<IApiDeleteResponse>(
        `${this.apiRequestService.buildEndpoint('admin/configuration')}/${configId}`,
        requestOptions
      ).pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(response => {
        if (response instanceof HttpErrorResponse) {
          this.logger.error('Unable to delete config.  ', response)
          return reject(response.error)
        }
        if (!response.success) {
          this.logger.error('Deleting config was not successful.  ', response.reason)
          return reject(response.reason)
        }
        this.logger.info(`Config [${configToDelete.name}] deleted.`)
        this._adminConfigs = this._adminConfigs.filter(c => c.id !== configId)
        resolve()
      })
    })
  }

  loadConfig(config: IConfig): void {
    this._config = signal(merge(
      this.create(),
      config,
    ))
  }

  loadConfigForAdmin(): Promise<void> {
    if (this._adminConfigs.length === 0) {
      this.logger.error(`There are no configs for this client!`)
      return Promise.reject()
    }

    if (this.loadConfigId || localStorage.getItem(this._localConfigKey) === null) {
      let fullConfig: IAdminConfigData | undefined
      if (this.loadConfigId) {
        this.logger.debug(`Trying to load config at catalog_id=[${this.loadConfigId}]`)
        fullConfig = this._adminConfigs.find(c => c.id === this.loadConfigId)
        this.loadConfigId = null
      }
      if (!fullConfig) {
        this.logger.debug(`Trying to load live config`)
        fullConfig = this._adminConfigs.find(c => c.is_live)
        if (!fullConfig) {
          this.logger.debug(`Trying to load first available config`)
          fullConfig = this._adminConfigs[0]
        }
      }
      this.saveLocally(fullConfig.config, {
        id: fullConfig.id,
        is_live: fullConfig.is_live,
        name: fullConfig.name,
        date_modified: fullConfig.date_modified,
      })
    }
    this.loadFromLocal()
    this.initialized = true
    return Promise.resolve()
  }

  loadFromLocal(): void {
    this._config = signal(merge(
      this.create(),
      this.parseLocal(),
    ))
    this._configMetadata = signal(this.parseLocalMetadata())
  }

  parseLocal(): any {
    let localConfig
    try {
      localConfig = localStorage.getItem(this._localConfigKey)
      if (localConfig) {
        return JSON.parse(localConfig)
      }
    } catch (e) {
      this.logger.error('Error parsing local config: ', localConfig)
    }
    return {}
  }

  parseLocalMetadata(): any {
    let localConfigMetadata
    try {
      localConfigMetadata = localStorage.getItem(this._localConfigMetadataKey)
      if (localConfigMetadata) {
        return JSON.parse(localConfigMetadata)
      }
    } catch (e) {
      this.logger.error('Error parsing local config metadata: ', localConfigMetadata)
    }
    return {}
  }

  get loadConfigId(): number | null {
    const configIdStr = localStorage.getItem(this._loadConfigIdKey)
    return configIdStr ? parseInt(configIdStr) : null
  }

  set loadConfigId(configId: number | null) {
    if (!configId) {
      localStorage.removeItem(this._loadConfigIdKey)
      return
    }
    localStorage.setItem(this._loadConfigIdKey, configId.toString())
  }

  get config(): WritableSignal<IConfig> {
    return this._config
  }

  get configMetadata(): WritableSignal<IConfigMetadata> {
    return this._configMetadata
  }

  get allConfigs(): IAdminConfigData[] {
    return this._adminConfigs
  }

  create(): IConfig {
    return {
      version: currentConfigVersion,
      sitemap: {
        static: [
          '/home',
          `${RoutePaths.Search}`
        ],
        dynamic: [
          {
            url: `${RoutePaths.Details}`,
            id: 'equipment.equipment_number',
            filters: [
              {
                'equipment_marketing.is_listed': true
              }
            ]
          }
        ]
      },
      seo: {
        data: {
          favicon_img_data: {
            width: 0,
            height: 0,
            kb: 0,
            name: '',
            originalFileName: '',
            updated: '',
            updatedFormatted: '',
            urls: {
              [ImageSizes.Favicon]: '',
            },
          },
          social_img_data: {
            height: 0,
            width: 0,
            kb: 0,
            name: '',
            originalFileName: '',
            updated: '',
            updatedFormatted: '',
            urls: {
              [ImageSizes.SocialMediaShare]: '',
            }
          },
        },
      },
      general: {
        data: {
          default_img_data: {
            height: 0,
            width: 0,
            kb: 0,
            name: '',
            originalFileName: '',
            updated: '',
            updatedFormatted: '',
            urls: {
              [ImageSizes.DefaultProductImage]: '',
            }
          },
        }
      },
      currency: {
        available: [CurrencyCode.USD],
        default: CurrencyCode.USD,
        enabled: true,
        template: 'basic',
        data: {
          styles: {},
          components: {},
        }
      },
      language: {
        options: {
          base: {
            available: [LanguageCode.EN_US],
            default: LanguageCode.EN_US,
          }
        },
      },
      theme: {
        id: 'light-theme',
        fonts: {},
        overrides: {},
      },
      header: {
        enabled: true,
        template: 'basic',
        data: {
          logo: {
            src_data: {
              name: '',
            },
          },
          styles: {},
          components: {},
        }
      },
      footer: {
        enabled: true,
        template: 'basic',
        data: {
          styles: {},
          components: {},
        }
      },
      legalTerms: {
        enabled: true,
        template: 'basic',
        data: {
          terms: '',
          styles: {},
          components: {},
        }
      },
      contactForm: {
        enabled: true,
        template: 'basic',
        data: {
          styles: {},
          components: {
            assetCard: {
              enabled: true,
              template: 'contact-form',
              data: {
                styles: {},
                components: {
                  badgesContainer: {
                    enabled: false,
                    template: 'basic',
                    data: {
                      styles: {},
                      components: {},
                    }
                  }
                },
              }
            }
          },
        }
      },
      page: {
        home: {
          seo: {
            data: {
              title: {
                ...defaultHomePageSiteTitle,
              },
              description: '',
            },
          },
          components: {
            heroBanner: {
              enabled: false,
              template: 'basic',
              data: {
                styles: {},
                components: {
                  search: {
                    enabled: true,
                    template: 'basic',
                    data: {
                      styles: {},
                      components: {},
                    }
                  },
                },
              }
            },
            featuredCarousel: {
              enabled: false,
              template: 'basic',
              data: {
                styles: {},
                components: {
                  assetCard: {
                    enabled: true,
                    template: 'basic',
                    data: {
                      styles: {},
                      components: {
                        badgesContainer: {
                          enabled: true,
                          template: 'basic',
                          data: {
                            styles: {},
                            components: {
                              badge: {
                                enabled: true,
                                template: 'basic',
                                data: {
                                  styles: {},
                                  components: {},
                                }
                              }
                            },
                          }
                        }
                      },
                    }
                  }
                },
              }
            },
            browseByCategory: {
              enabled: false,
              template: 'basic',
              data: {
                styles: {},
                components: {},
              }
            },
            contactFormSection: {
              enabled: false,
              template: 'basic',
              data: {
                styles: {},
                components: {},
              }
            },
          },
        },
        search: {
          seo: {
            data: {
              title: {
                ...defaultSearchPageSiteTitle,
              },
              description: '',
            },
          },
          components: {
            search: {
              enabled: true,
              template: 'basic',
              data: {
                styles: {},
                components: {},
              }
            },
            searchGrid: {
              enabled: true,
              template: 'basic',
              data: {
                styles: {},
                components: {
                  assetCard: {
                    enabled: true,
                    template: 'basic',
                    data: {
                      enableButtons: false,
                      styles: {},
                      components: {
                        badgesContainer: {
                          enabled: true,
                          template: 'basic',
                          data: {
                            styles: {},
                            components: {
                              badge: {
                                enabled: true,
                                template: 'basic',
                                data: {
                                  styles: {},
                                  components: {},
                                }
                              }
                            },
                          }
                        }
                      },
                    }
                  }
                },
              }
            },
            filterContainer: {
              enabled: true,
              template: 'basic',
              data: {
                styles: {},
                components: {},
              }
            },
          },
        },
        details: {
          seo: {
            data: {
              title: {
                ...defaultDetailsPageSiteTitle,
              },
              description: '',
            }
          },
          components: {
            shareButton: {
              enabled: true,
              template: 'basic',
              data: {
                styles: {},
                components: {},
              }
            },
            leadForm: {
              enabled: true,
              template: 'basic',
              data: {
                styles: {},
                components: {},
              }
            },
            quickLinksBanner: {
              enabled: true,
              template: 'basic',
              data: {
                styles: {},
                components: {},
              }
            },
            similarCarousel: {
              enabled: false,
              template: 'basic',
              data: {
                styles: {},
                components: {
                  assetCard: {
                    enabled: true,
                    template: 'basic',
                    data: {
                      styles: {},
                      components: {
                        badgesContainer: {
                          enabled: true,
                          template: 'basic',
                          data: {
                            styles: {},
                            components: {
                              badge: {
                                enabled: true,
                                template: 'basic',
                                data: {
                                  styles: {},
                                  components: {},
                                }
                              }
                            },
                          }
                        }
                      },
                    }
                  }
                },
              }
            },
            recentlyViewedCarousel: {
              enabled: false,
              template: 'basic',
              data: {
                styles: {},
                components: {
                  assetCard: {
                    enabled: true,
                    template: 'basic',
                    data: {
                      styles: {},
                      components: {
                        badgesContainer: {
                          enabled: true,
                          template: 'basic',
                          data: {
                            styles: {},
                            components: {
                              badge: {
                                enabled: true,
                                template: 'basic',
                                data: {
                                  styles: {},
                                  components: {},
                                }
                              }
                            },
                          }
                        }
                      },
                    }
                  }
                },
              }
            },
            assetDetailsHeader: {
              enabled: true,
              template: 'basic',
              data: {
                styles: {},
                components: {},
              }
            },
            assetInformation: {
              enabled: true,
              template: 'basic',
              data: {
                showConfiguration: true,
                styles: {},
                components: {},
              }
            },
          },
        },
      }
    }
  }
}
